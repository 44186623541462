<ng-container *ngIf="!summaryReady || !nsrTrendReady || !nsrDailyReady || !nsrMonthlyReady || !gpMonthlyReady || !salePerformance">
    <div class="text-center spinner-wrapper">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>
<!-- <input id="tuyen" type="hidden" value="{{summaryReady}},{{nsrTrendReady}},{{nsrDailyReady}},{{nsrMonthlyReady}},{{salePerformance}}"> -->
<div [class]="summaryReady && nsrTrendReady &&  nsrDailyReady  && nsrMonthlyReady && gpMonthlyReady && salePerformance ? '' : 'not-show' ">

    <div class="filter-section no-title mega-wrapper">
        <div class="ng-section summary-section date-picker">
            <b>Date</b>
            <input [(ngModel)]="selectedDate" (dateSelect)="onDateSelect($event)" type="text" ngbDatepicker
            [minDate]="{year: 2025, month: 1, day: 1}"
            [maxDate]="{year: startDate?.year, month: startDate?.month, day: startDate?.day }"
            #d="ngbDatepicker" class="form-control" readonly placeholder="Choose a date" (click)="d.toggle()" />
        </div>

        <!-- <div class="ng-section summary-section native">
            <b>Aggregate By</b>
            <select class="form-control" (change)="onAggregateByChange($event.target.value)">
                <option value="daily">Date Numbers</option>
                <option value="mtd">Month to Date Numbers</option>
                <option value="qtd">Quarter to Date Numbers</option>
                <option value="ytd">Year to Date Numbers</option>
            </select>
        </div> -->
        <single-dropdown title="Aggregate By"
            [items]="[{value: 'daily', name: 'Date Numbers'}, {value: 'mtd', name: 'Month to Date Numbers'}, {value: 'qtd', name: 'Quarter to Date Numbers'}, {value: 'ytd', name: 'Year to Date Numbers'}]"
            [model] = "aggregateBy"
            (change)="onAggregateByChange($event)">
        </single-dropdown>

        <!-- <comment-button class="d-flex align-items-center" title1="Dashboard Comments" chartId="dashboard" [chatIdDependency]="[{selectedDate}]" type="SummaryRemarks"></comment-button> -->

    </div>
    <p></p>
    <!-- <comment-button title1="Dashboard Comments" chartId="dashboard" [chatIdDependency]="[{selectedDate}]" type="OwnerComments"></comment-button> -->
    <p></p>
    <summary-dashboard [selectedDate]="selectedDate" [dailyData]="dailyDataHist" [monthlyData]="monthlyDataHist" [aggregateBy]="aggregateBy" (ready)="summaryCheckReady($event)"></summary-dashboard>
    <div class="row mb-30">
        <nsr-trend class="col-lg-9 col-md-12" [monthlyData]="monthlyData" [selectedDate]="selectedDate" (ready)="nsrTrendCheckReady($event)"></nsr-trend>
        <nsr-daily class="col-lg-3 col-md-12" [dailyData]="dailyDataHist" [monthlyData]="monthlyDataHist" [selectedDate]="selectedDate" [aggregateBy]="aggregateBy" (ready)="nsrDailyCheckReady($event)"></nsr-daily>
    </div>
    <nsr-monthly [monthlyData]="monthlyFullData" [selectedDate]="selectedDate" (ready)="nsrMonthlyCheckReady($event)"></nsr-monthly>
    <gp-monthly [monthlyData]="monthlyFullData" [selectedDate]="selectedDate" (ready)="gpMonthlyCheckReady($event)"></gp-monthly>
    <sale-performance [monthlyData]="monthlyDataHist" [selectedDate]="selectedDate" (ready)="salePerformanceCheckReady($event)"></sale-performance>
</div>